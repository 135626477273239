@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

#comet-container * {
    touch-action: none !important;
}

html {
    font-size: 14px;
}

@media only screen and (min-width: 720px) {
    html {
        font-size: 15px;
    }
}

@media only screen and (min-width: 960px) {
    html {
        font-size: 16px;
    }
}

.comet-notification {
    @apply max-w-sm w-full bg-white shadow-lg border border-gray-300 pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden;
}

.icon-holder {
    @apply rounded p-2 rounded hover:bg-gray-100 cursor-pointer duration-150 flex items-center justify-center;
}

.toolbar {
    @apply border border-gray-200 p-1 absolute bg-white z-10;
}

.required-styles {
    @apply bg-indigo-500 bg-yellow-500 bg-green-500 bg-pink-500 bg-gray-400 text-gray-500 h-8 w-8;
}

.swatch {
    @apply rounded-full h-6 w-6 duration-150 cursor-pointer;
}

.swatch:hover {
    @apply opacity-75;
}

#comet-container {
    @tailwind components;
    @tailwind utilities;

    .text-primary {
        color: #6E6EFF;
    }

    .bg-primary {
        background-color: #6E6EFF;
    }

    #mouse-pointers {
        position:absolute;
    }

    .progress-ring__circle {
        transition: 0.55s;
        transform-origin: center;
        transform: rotate(-90deg);
    }

    .pointer-container {
        display: flex;
        width: fit-content;
        position:absolute;
        top:20px;
        left:40px;
        z-index: 2;
        transition: 0.5s ease-out;
    }

    .mouse-pointer {
        height:28px;
        width: 28px;
    }

    .name-tag {
        width:fit-content;
        white-space: nowrap;
        margin-bottom:-5px;
        font-size:0.7em;
        margin-top:15px;
        background-color: rebeccapurple;
        display: flex;
        align-items: center;
        padding-bottom: 1.6px;
        padding-right: 5px;
        padding-left: 5px;
        color: white;
    }

    .tippy-box[data-theme~='comet'] {
        background-color: #424b54;
        color: white;
        font-weight: bold;
        border-radius: 0px;
    }

    .tippy-box[data-theme~='comet'][data-placement^='top'] > .tippy-arrow::before {
        border-top-color: #424b54;
    }
    .tippy-box[data-theme~='comet'][data-placement^='bottom'] > .tippy-arrow::before {
        border-bottom-color: #424b54;
    }
    .tippy-box[data-theme~='comet'][data-placement^='left'] > .tippy-arrow::before {
        border-left-color: #424b54;
    }
    .tippy-box[data-theme~='comet'][data-placement^='right'] > .tippy-arrow::before {
        border-right-color: #424b54;
    }

    .tippy-box[data-theme~='box'] {
        --tw-text-opacity: 1;
        color: rgba(107, 114, 128, var(--tw-text-opacity));
        border: 1px solid #D1D5DB;
        background-color: white;
        border-radius: 0px;
    }

    .rotate {
        -webkit-animation: pp-rotate-anim 0.25s 1 ease-in-out;
    }

    @keyframes pp-rotate-anim {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(-359deg);
        }
    }

    .try-to-undo {
        animation: pp-try-to-undo 0.5s ease-in-out 0s 1 normal forwards;
    }

    @keyframes pp-try-to-undo {
        0.0%{
            transform: scale(1);
        }
        20.1%{
            transform: scale(1) rotate(-30deg);
        }
        60.1%{
            transform: scale(1) rotate(15deg);
        }
        79.6%{
            transform: scale(1) rotate(-5deg);
        }
        99.5%{
            transform: scale(1) rotate(0deg);
        }
    }

    .try-to-redo {
        animation: pp-try-to-redo 0.5s ease-in-out 0s 1 normal forwards;
    }

    @keyframes pp-try-to-redo {
        0.0%{
            transform: scale(1);
        }
        20.1%{
            transform: scale(1) rotate(30deg);
        }
        60.1%{
            transform: scale(1) rotate(-15deg);
        }
        79.6%{
            transform: scale(1) rotate(5deg);
        }
        99.5%{
            transform: scale(1) rotate(0deg);
        }
    }


    .front-roll {
        -webkit-animation: pp-front-roll-anim 0.25s 1 ease-in-out;
    }

    @keyframes pp-front-roll-anim {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }

    @keyframes pp-slide-in {
        0% {
            opacity: 0;
            transform: translateX(10px);
        }

        100% {
            opacity: 100;
            transform: translateX(0px);
        }
    }

    @keyframes pp-slide-out {
        0% {
            opacity: 100;
            transform: translateX(0px);
        }

        100% {
            opacity: 0;
            transform: translateX(10px);
        }
    }

    @keyframes pp-slide-down {
        0% {
            opacity: 0;
            transform: translateY(10px);
        }

        100% {
            opacity: 100;
            transform: translateY(0px);
        }
    }

    @keyframes pp-slide-up {
        0% {
            opacity: 100;
            transform: translateY(0px);
        }

        100% {
            opacity: 0;
            transform: translateY(10px);
        }
    }

    @keyframes pp-slide-down-sm {
        0% {
            opacity: 0;
            transform: translateY(5px);
        }

        100% {
            opacity: 100;
            transform: translateY(0px);
        }
    }

    @keyframes pp-slide-up-sm {
        0% {
            opacity: 100;
            transform: translateY(0px);
        }

        100% {
            opacity: 0;
            transform: translateY(5px);
        }
    }

    @keyframes pp-pop-in {
        0% {
            opacity: 0;
            transform: scale(0.8);
        }

        100% {
            opacity: 100;
            transform: scale(1);
        }
    }

    @keyframes pp-pop-out {
        0% {
            opacity: 100;
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(0.8);
        }
    }

    @keyframes pp-animated-fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 100;
        }
    }

    @keyframes pp-animated-fade-out {
        0% {
            opacity: 100;
        }

        100% {
            opacity: 0;
        }
    }

    .animated-slide-in {
        animation-duration: 0.4s;
        animation-name: pp-slide-in;
    }

    .animated-slide-out {
        animation-duration: 0.4s;
        animation-name: pp-slide-out;
    }

    .animated-slide-down {
        animation-duration: 0.3s;
        animation-name: pp-slide-down;
    }

    .animated-slide-up {
        animation-duration: 0.3s;
        animation-name: pp-slide-up;
    }

    .animated-slide-down-fast {
        animation-duration: 0.15s;
        animation-name: pp-slide-down-sm;
    }

    .animated-slide-up-fast {
        animation-duration: 0.15s;
        animation-name: pp-slide-up-sm;
    }

    .bg-gray-100 {
        background-color: #F3F4F6;
    }

    .animated-pop-in {
        animation-duration: 0.3s;
        animation-name: pp-pop-in;
    }

    .animated-pop-out {
        animation-duration: 0.3s;
        animation-name: pp-pop-out;
    }

    .fade-in {
        animation-duration: 0.2s;
        animation-name: pp-animated-fade-in;
    }

    .fade-out {
        animation-duration: 0.2s;
        animation-name: pp-animated-fade-out;
    }
}